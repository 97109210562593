<template>
	<div>
		<pcHeader />
		<div>
			<router-view />
		</div>
		<pcFooter style="position:relative" />
	</div>
</template>

<script>
import pcHeader from '@/components/pcHeader'
import pcFooter from '@/components/pcFooter'
export default {
	components: {
		pcHeader,
		pcFooter
	}
}
</script>

<style></style>
