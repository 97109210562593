<!--
 * @Author: tjessie
 * @Date: 2023-11-01 15:16:47
 * @LastEditors: tjessie
 * @LastEditTime: 2023-11-21 10:00:44
 * @Description: file content
 * @FilePath: \studyAbroad-PC\src\components\contactPopup.vue
-->
<template>
	<div
		v-if="isPopup"
		:class="
			isAnimate
				? 'contact_popup_box flexVerticalCenter animate__animated animate__fadeIn'
				: 'contact_popup_box flexVerticalCenter animate__animated animate__fadeOut'
		"
	>
		<div class="contact_popup_bg"></div>
		<div class="contact_popup flexVerticalCenter">
			<img class="contact_popup_img" :src="contactUrl" alt="" srcset="" />
			扫码添加留学顾问免费咨询
			<img
				class="contact_popup_close"
				src="https://qn.juquedu.com/close_icon.png"
				@click="closePopup"
				alt=""
				srcset=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	name: 'contactPopup',
	data() {
		return {
			isPopup: false,
			isAnimate: false,
			contactUrl: '',
			isPcHeader: false
		}
	},
	created() {},
	methods: {
		openPopup(type) {
			if (type == 'pcHeader') {
				this.isPcHeader = true
			}
			this.isPopup = true
			this.isAnimate = true
			this.contactUrl = sessionStorage.getItem('qrurl')
			console.log('contactUrl', this.contactUrl)
		},
		closePopup() {
			this.isAnimate = false
			setTimeout(() => {
				this.isPopup = false
				if (this.isPcHeader) {
					this.isPcHeader = false
					this.$parent.closeContactPopup()
				}
			}, 1000)
		}
	}
}
</script>

<style lang="scss" scoped>
.contact_popup_box {
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 99;
	.contact_popup_bg {
		width: 100%;
		height: 100%;
		background: #000000;
		opacity: 0.4;
		position: fixed;
		top: 0;
		left: 0;
		z-index: 1;
	}
	.contact_popup {
		width: 316px;
		height: 369px;
		padding: 40px 32px;
		box-sizing: border-box;
		background: #ffffff;
		border-radius: 12px;
		font-size: 20px;
		font-weight: bold;
		color: #333333;
		z-index: 2;
		flex-direction: column;
		position: relative;
		.contact_popup_img {
			width: 236px;
			height: 236px;
			margin: 0 0 25px;
		}
		.contact_popup_close {
			width: 15px;
			height: 15px;
			position: absolute;
			top: 10px;
			right: 10px;
			cursor: pointer;
		}
	}
}
</style>
