<template>
	<div
		:class="
			!isShowTab
				? isChangeLayout
					? 'content animate__animated animate__fadeOut'
					: 'content animate__animated animate__fadeIn'
				: 'content'
		"
		:style="{ background: isBackground ? '#ffffff' : '' }"
	>
		<div class="main nav">
			<div
				class="logo_box flexCenter"
				:style="{ color: isBackground ? '#333333' : '#ffffff' }"
			>
				<img
					v-show="!isBackground"
					class="logo"
					@click="goHome"
					src="https://qn.juquedu.com/logo_white.png"
					alt=""
				/>
				<img
					v-show="isBackground"
					class="logo"
					@click="goHome"
					src="https://qn.juquedu.com/logo_black.png"
					alt=""
				/>
				<span>|</span>
				留学频道
			</div>
			<div
				class="navList"
				:style="{ color: isBackground ? '#333333' : '#ffffff' }"
			>
				<div
					class="item"
					@click="select(item)"
					v-for="(item, i) in navList"
					:key="i"
				>
					<div
						:class="item.index == navIndex ? 'theme_font' : ''"
						:style="{
							'font-weight': item.index == navIndex ? 'bold' : ''
						}"
					>
						{{ item.title }}
					</div>
					<div
						:class="item.index == navIndex ? 'index theme_bg' : ''"
					></div>
				</div>
				<div class="contact_out">
					<div
						class="contact flexCenter"
						@click="openContactPopup"
						:style="{ color: isBackground ? '#333333' : '#ffffff' }"
					>
						<img
							v-show="!isBackground"
							class="contact_icon"
							src="https://qn.juquedu.com/wechat_white_icon.png"
							alt=""
							srcset=""
						/>
						<img
							v-show="isBackground"
							class="contact_icon"
							src="https://qn.juquedu.com/wechat_black_icon.png"
							alt=""
							srcset=""
						/>
						联系我们
					</div>
				</div>
			</div>
		</div>
		<ContactPopup ref="contactPopup"></ContactPopup>
	</div>
</template>

<script>
import ContactPopup from '@/components/contactPopup.vue'
export default {
	data() {
		return {
			isShowTab: true,
			isChangeLayout: false,
			scrollTop: 0, // 记录当前的滚动距离
			oldScrollTop: 0, //记录上一次滚动结束后的滚动距离
			item: {},
			navIndex: 1,
			navList: [{ index: 1, title: '首页', query: {} }],
			isBackground: false,
			isOpenPopup: false,
			isBackgroundNum: 0
		}
	},
	components: {
		ContactPopup
	},
	created() {
		this.getData()
	},
	mounted() {
		window.addEventListener('scroll', this.windowScroll) //监听页面滚动
	},
	watch: {
		scrollTop(newValue, oldValue) {
			setTimeout(() => {
				if (newValue == window.scrollY) {
					//延时执行后当newValue等于window.scrollY，代表滚动结束
					console.log('滚动结束')
					this.oldScrollTop = newValue //每次滚动结束后都要给oldScrollTop赋值
					this.isChangeLayout = false
				}
			}, 1000) //必须使用延时器，否则每次newValue和window.scrollY都相等，无法判断，20ms刚好大于watch的侦听周期，故延时20ms
			if (this.oldScrollTop == oldValue) {
				//每次滚动开始时oldScrollTop与oldValue相等
				console.log('滚动开始')
			}
		},
		$route: {
			// $route可以用引号，也可以不用引号
			handler() {
				this.isShowTab = true
				if (this.$route.query.directionId) {
					this.isBackground = true
				} else {
					this.isBackground = false
				}
				if (this.$route.query.countryId) {
					this.navList.forEach((item) => {
						if (
							this.$route.query.countryId == item.query.countryId
						) {
							this.navIndex = item.index
						}
					})
				} else {
					this.navIndex = 1
				}
			},
			deep: true, // 深度观察监听
			immediate: true // 第一次初始化渲染就可以监听到
		}
	},
	methods: {
		// 打开联系我们弹窗
		openContactPopup() {
			this.isOpenPopup = true
			this.$refs.contactPopup.openPopup('pcHeader')
		},
		// 关闭联系我们弹窗
		closeContactPopup() {
			this.isOpenPopup = false
		},
		// 监听页面滚动 获取滚动条位置
		windowScroll() {
			let scrollTop =
				window.pageYOffset ||
				document.documentElement.scrollTop ||
				document.body.scrollTop
			if (scrollTop > 0) {
				if (this.scrollTop > 0) {
					if (!this.isOpenPopup) {
						this.isShowTab = false
					}
					if (this.scrollTop >= scrollTop) {
						this.isChangeLayout = false
					} else {
						if (!this.isOpenPopup) {
							this.isChangeLayout = true
							setTimeout(() => {
								if (this.scrollTop > 0) {
									this.isBackground = true
								}
							}, 1000)
						}
					}
				} else {
					this.isShowTab = true
				}
			} else {
				this.isBackground = false
			}
			this.scrollTop = scrollTop
		},
		goHome() {
			this.navIndex = 1
			sessionStorage.navIndex = 1
			this.$router.push({
				path: '/home'
			})
		},
		select(item) {
			this.navIndex = item.index
			sessionStorage.item = JSON.stringify(item)
			if (item.title == '首页') {
				this.goHome()
			} else {
				this.$router.push({
					path: '/country',
					query: item.query
				})
				this.click(item.query.countryId)
			}
		},
		// 记录点击事件
		async click(countryId) {
			var params = new URLSearchParams()
			params.append('countryId', countryId)
			const { data: res } = await this.$http.post(
				'hwapp/juquHwWwwPage/un/click',
				params
			)
			if (res.status == 1001) {
				console.log(res)
			}
		},
		// 获取数据
		async getData() {
			var params = new URLSearchParams()
			params.append('sort', 'sort,asc')
			const { data: res } = await this.$http.post(
				'hwapp/juquHwWwwPage/un/get',
				params
			)
			if (res.status == 1001) {
				// res.data.sort((a, b) => {
				// 	return a.sort - b.sort
				// }) // 升序
				var data = []
				data = res.data
				sessionStorage.setItem('qrurl', data.qrurl)
				data.countryList.forEach((item, i) => {
					item.pathIndex = parseInt(i + 2)
					sessionStorage.setItem(
						item.id + 'CountryData',
						JSON.stringify(item)
					)
					this.navList.push({
						index: item.pathIndex,
						title: item.name,
						query: { countryId: item.id }
					})
					if (this.$route.query.countryId == item.id) {
						this.navIndex = item.pathIndex
					}
				})
				sessionStorage.setItem('countryTabData', JSON.stringify(data))
			}
		}
	}
}
</script>

<style lang="scss" scoped>
.content {
	position: fixed;
	top: 0;
	z-index: 99;
	width: 100%;
}
.nav {
	display: flex;
	align-items: center;
	height: 98px;
	justify-content: space-between;
	.logo_box {
		font-size: 20px;
		font-weight: 800;
		color: #ffffff;
		.logo {
			width: 160px;
			height: 32px;
		}
		.logo:hover {
			cursor: pointer;
		}
		span {
			padding: 0 8px;
		}
	}
	.navList {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-size: 18px;
		height: 100%;
		color: #ffffff;
		.item {
			cursor: pointer;
			height: 100%;
			line-height: 98px;
			margin-right: 58px;
			font-size: 18px;
			position: relative;
			.index {
				width: 18px;
				margin: auto;
				content: '';
				position: relative;
				bottom: 16px;
				display: block;
				height: 3px;
				text-align: center;
				background-color: #ffffff;
				border-radius: 3px;
				animation: fadeInLeft 0.3s ease 0.2s 1 both;
			}
			.website_list {
				display: none;
				// width: 160px;
				height: 183px; //8px的距离上部距离
				// position: relative;
				.website_list_item {
					width: 160px;
					color: #191919;
					font-size: 16px;
					position: absolute;
					top: 72px;
					box-shadow: 0px 4px 14px rgba(0, 0, 0, 0.12);
					border-radius: 8px;
					.border_bottom {
						width: 124px;
						border-bottom: 1px #f1f1f1 solid;
						position: absolute;
						bottom: 0;
					}
					li {
						width: 100%;
						height: 58px;
						line-height: 58px;
						background-color: #ffffff;
						padding: 0 18px;
						box-sizing: border-box;
						position: relative;
					}
					li:first-child {
						border-top-left-radius: 8px;
						border-top-right-radius: 8px;
					}
					li:last-child {
						border-bottom-left-radius: 8px;
						border-bottom-right-radius: 8px;
					}
					li:hover {
						color: #2532e2;
						background-color: #f5f5f5;
					}
				}
			}
		}
		.item:hover {
			// color: #ecc02c;
			.website_list {
				display: block;
			}
		}
		.item:last-child {
			margin-right: 72px;
		}
		.contact_out {
			position: relative;
			.contact {
				width: 120px;
				height: 42px;
				line-height: 42px;
				text-align: center;
				font-size: 18px;
				font-weight: bold;
				color: #ffffff;
				border-radius: 21px;
				cursor: pointer;
				.contact_icon {
					width: 25px;
					height: 25px;
					margin-right: 8px;
				}
			}
		}
	}
}
</style>
